import React from 'react';
import ReactDOM from 'react-dom';
import {Auth0Provider} from "@auth0/auth0-react";
import {App} from "./App";
import queryString from 'query-string'
import './index.css';

const getAppSet = () => {
    let parsedQuery = queryString.parse(window.location.search);
    return (parsedQuery.appSet !== undefined) ? parsedQuery.appSet : 'delta'
}

export const setUrl = (appSet) => {
    return window.location.origin + "/?appSet=" + appSet;
}
ReactDOM.render(
    /**
     * This Auth0 configuration allows __ALL__ Metapack employees to access this page.
     * It is to personalise access to this page only! (and prevent external users from viewing our resources)
     *
     * When opening any of DM / DM Scheduler links a separate Auth0 Application is used
     * controlling correct rights for execution
     */
    <Auth0Provider
        domain="login.sbx.metapack.com"
        clientId="lgsOvlqqZzuQ8DB2m3HtBhXWPAjQpnr7"
        redirectUri={setUrl(getAppSet())}
        organization="org_0wmNPNsxaenfZsNE"
    >
        <App appSet={getAppSet()}/>
    </Auth0Provider>,
    document.getElementById("root")
);
