import {useState, useCallback} from 'react';
import {Tile} from "./Tile";
import {useDrop} from "react-dnd";
import {ItemTypes} from "./ItemTypes";

export const Container = (props) => {

    const reorderTiles = useCallback((id, hoverOndex) => props.reorder(props.containerId, id, hoverOndex), [props]);

    const [{isHovered}, drop] = useDrop({
        accept: ItemTypes.TILE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                isHovered: monitor.isOver({shallow: true}) && monitor.getItem().containerId !== props.containerId
            };
        },
        canDrop(item, monitor) {
            if (item.containerId === props.containerId) {
                return false;
            }
            return true;
        },
        drop(item, monitor) {
            props.move(item.id, item.containerId, props.containerId);
        },
    });


    const renderTile = (tile, index) => {
        return (
            <Tile key={tile.id} index={index} id={tile.id} img={tile.img} text={tile.text} url={tile.url}
                  moveTile={reorderTiles} containerId={tile.containerId}/>
        );
    };

    const addTiles = (tiles, isHidden) => {
        if (!isHidden) {
            return (
                <section className="tiles">
                    {tiles.map((tile, i) => renderTile(tile, i))}
                </section>
            )
        }
        return (<section className="tiles"/>)
    }

    const [isHidden, setHidden] = useState(props.hidden);
    const handleArrowClick = () => {
        setHidden(!isHidden)
    }

    const arrowDirection = (isHidden) => {
        return isHidden ? "rotate(180 8 8)" : ""
    };

    const addDropDownOverlay = (tiles, isHidden, isDropDown) => {
        if (!isDropDown) {
            return;
        }
        if (isHidden || tiles.length === 0) {
            return (<>
                <section className="spacer">
                    <div className="spacer-div"/>
                </section>
                <div className="drop-down-overlay">
                    <br/>Drop app here to add to this section
                </div>
            </>);
        }

        return (
            <div className="drop-down-overlay">
                <br/>Drop app here to add to this section
            </div>);
    }
    return (
        <div className="main-container" ref={drop}>
            <header className="container-header">
                <button className="container-header-button" onClick={handleArrowClick}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M7.54309 7.23955C7.79018 6.97481 8.20982 6.97481 8.45691 7.23955L11.0431 10.0105C11.2902 10.2752 11.7098 10.2752 11.9569 10.0105L11.977 9.98895C12.2011 9.74882 12.2011 9.37618 11.977 9.13605L8.46009 5.36795C8.21183 5.10196 7.78974 5.10342 7.54333 5.37111L4.02817 9.18988C3.80296 9.43454 3.81111 9.81337 4.04663 10.0481V10.0481C4.29458 10.2953 4.6979 10.288 4.93676 10.032L7.54309 7.23955Z"
                              fill="#212126" transform={arrowDirection(isHidden)}/>
                        <circle cx="8" cy="8" r="7.25" stroke="#212126" strokeWidth="1.5"/>
                    </svg>
                    <span className="container-header-text">{props.title}</span>
                </button>
            </header>
            <div className="tiles-area">
                {addTiles(props.items, isHidden)}
                {addDropDownOverlay(props.items, isHidden, isHovered)}
            </div>
        </div>
    );
};
