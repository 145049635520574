import {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {ItemTypes} from './ItemTypes';

export const Tile = ({id, text, index, img, moveTile, url, containerId}) => {

    const ref = useRef(null);
    const [{handlerId, isHovered}, drop] = useDrop({
        accept: ItemTypes.TILE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                isHovered: monitor.isOver({shallow: true}) && (monitor.getItem().containerId === containerId)
            };
        },
        hover(item, monitor) {
            // Don't replace items with themselves
            if (item.id === id) {
                return;
            }
            // Don't replace items in other container
            if (item.containerId !== containerId) {
                return;
            }
            moveTile(item.id, index);
        },
        canDrop(item, monitor) {
            // dont drop to other group
            if (item.containerId !== containerId) {
                return false;
            }
            return true;
        },
        drop(item, monitor) {
            // items are immediately reordered in 'hover' method.
            // Nothing more to do
            return;
        },
    });

    const [collected, dragRef] = useDrag({
        type: ItemTypes.TILE,
        item: () => {
            return {id, containerId};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    let className = "tile";
    const opacity = collected.isDragging ? 0.3 : 0.3;
    if (isHovered) {
        className += " tile-highlighted";
    }
    dragRef(drop(ref));
    return (
        <div ref={ref} style={{...opacity}} data-handler-id={handlerId} data-se="app-card-container" className={className}
             draggable="true">
            <a className="tile-a"
               href={url}
               target="_blank" rel="noopener noreferrer">
                <article className="chiclet--article">
                    <section className="chiclet--main" data-se="app-card-main"><img className="app-logo--image"
                                                                                    src={img}
                                                                                    alt="Slack logo"/></section>
                    <footer className="chiclet--footer" data-se="app-card-footer">
                        {text}
                    </footer>
                </article>
            </a>
            <button className="chiclet--action" tabIndex="0" aria-label="Settings for Slack"
                    data-se="app-card-settings-button">
                <svg className="chiclet--action-kebab" width="20" height="4" viewBox="0 0 20 4" fill="#B7BCC0"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2"/>
                    <circle cx="10" cy="2" r="2"/>
                    <circle cx="18" cy="2" r="2"/>
                </svg>
            </button>
        </div>
    )
};
