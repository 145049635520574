import React from "react";

// TODO: add list of Containers for re-ordering and quick navigation
export const Side = () => {
    return (<div className="side-nav">
        <div className="side-nav-content">
        </div>
        <div className="side-nav-copyright">
            &copy; Metapack 2022
        </div>
    </div>);
}
