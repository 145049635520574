import {useCallback, useState} from 'react';
import {Container} from "./Container";
import update from "immutability-helper";


export const Containers = ({items, tabs}) => {

    const [layout, setLayout] = useState(null);

    // ugly hack to reload page after tiles have been changed.
    const [reload, doReload] = useState(0);

    // initialise layout
    let mapTest = layout;
    if (mapTest === null) {
        mapTest = tabs.map(tab => {
            return tab
        });
        mapTest.forEach(tab => {
            tab.items = items.filter(it => it.containerId === tab.id);
        })
        setLayout(mapTest);
    }

    // move tile to another container
    const moveTile = useCallback((id, fromContainer, toContainer) => {
        const mapTest = layout;
        const src = mapTest.find(cnt => cnt.id === fromContainer);
        const dst = mapTest.find(cnt => cnt.id === toContainer);
        const itemToMove = src.items.findIndex(it => it.id === id);
        src.items[itemToMove].containerId = toContainer;
        dst.items.push(src.items[itemToMove]);
        src.items.splice(itemToMove, 1);
        setLayout(mapTest);

        // force page reload
        doReload(reload + 1);
    }, [layout, reload]);

    // reorder tiles within same container
    const reorder = useCallback((containerId, id, hoverIndex) => {
        const mapTest = layout;
        const src = mapTest.find(cnt => cnt.id === containerId);
        const itemToMove = src.items.findIndex(it => it.id === id);
        if (itemToMove !== undefined) {
            const dragTile = src.items[itemToMove];
            src.items = update(src.items, {
                $splice: [
                    [itemToMove, 1],
                    [hoverIndex, 0, dragTile],
                ],
            })
            setLayout(mapTest);

            // force page reload
            doReload(reload + 1);
        }
    }, [layout, reload]);

    const showContainer = (row) => {
        return (
            <Container key={row.id} title={row.text} hidden={row.hidden} containerId={row.id} items={row.items}
                       move={moveTile} reorder={reorder}/>
        );
    };
    return (
        <main className="containers-area">
            <section className="tiles">
                {mapTest.map(row => showContainer(row))}
            </section>
        </main>
    );
};
