import React from "react";
import {useAuth0} from "@auth0/auth0-react";

export const TopMenu = () => {
    const {user} = useAuth0();
    return (<div className="top-menu">
        <img className="top-menu-logo" alt="logo" src="metapack-logo.png"/>
        <br/>
        <span className="user-details">
                Hi {user.given_name}
            </span>
    </div>);
}
